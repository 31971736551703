import React from 'react'

import { Center, Flex, useRadioGroup } from '@chakra-ui/react'
import CustomRadioItem from '../CustomRadioItem'
import OnboardingCard from '../OnboardingCard'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../hooks'
import { setCurrentTime } from '../../reducers/trainingSettingsReducer'
import { MAXIMUM_T_DURATION, MINIMUM_T_DURATION } from '../../constants/durations'
import _ from 'lodash'
import { ONBOARDING_STEPS } from '../../App'

const radioOptions = [
  { text: 'nie', value: 0 },
  { text: '< 2.5h', value: 75 },
  { text: '2.5h - 5h', value: 150 },
  { text: '5h - 7.5h', value: 300 },
  { text: '> 7.5h', value: 450 },
]

const CurrentTimeForActivities = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { getRootProps, getRadioProps, value } = useRadioGroup({
    defaultValue: '0',
  })
  const group = getRootProps()

  const handleNext = () => {
    let currentTime = parseInt(value as string)
    currentTime = _.clamp(currentTime, MINIMUM_T_DURATION, MAXIMUM_T_DURATION)
    dispatch(setCurrentTime(currentTime))
    return navigate('/onboarding/' + ONBOARDING_STEPS.plannedHardTraining.key)
  }

  return (
    <OnboardingCard
      title='Wie aktiv bist du derzeit pro Woche?'
      onNextClicked={handleNext}
      isNextDisabled={!value}
    >
      <Center h='full'>
        <Flex direction='column' gap='2' w='50' {...group}>
          {radioOptions.map((option) => {
            const radio = getRadioProps({ value: option.value.toString() })
            return (
              <CustomRadioItem key={option.value} {...radio}>
                {option.text}
              </CustomRadioItem>
            )
          })}
        </Flex>
      </Center>
    </OnboardingCard>
  )
}

export default CurrentTimeForActivities
