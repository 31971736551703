import { Day, DayStatus } from './day'

export type Week = Day[]

const WEEK: Week = [
  { short: 'MO', long: 'Montag', dayOfWeek: 0, trainings: [], status: DayStatus.NOTAVAILABLE },
  { short: 'DI', long: 'Dienstag', dayOfWeek: 1, trainings: [], status: DayStatus.NOTAVAILABLE },
  { short: 'MI', long: 'Mittwoch', dayOfWeek: 2, trainings: [], status: DayStatus.NOTAVAILABLE },
  { short: 'DO', long: 'Donnerstag', dayOfWeek: 3, trainings: [], status: DayStatus.NOTAVAILABLE },
  { short: 'FR', long: 'Freitag', dayOfWeek: 4, trainings: [], status: DayStatus.NOTAVAILABLE },
  { short: 'SA', long: 'Samstag', dayOfWeek: 5, trainings: [], status: DayStatus.NOTAVAILABLE },
  { short: 'SO', long: 'Sonntag', dayOfWeek: 6, trainings: [], status: DayStatus.NOTAVAILABLE },
]

const getAvailableDays = (days: Day[]) => {
  return days.filter(
    (day) =>
      day.status === DayStatus.AVAILABLE ||
      day.status === DayStatus.PREFERRED_HARD ||
      day.status === DayStatus.PREFERRED_LONG,
  )
}

export { WEEK, getAvailableDays }
