import { BLUE, GREEN, RED, YELLOW } from '../constants/colors'

export type Zone = {
  number: number
  color: string
  rpe: number[]
  min: number
  opti: number
  max: number
}

const ZONES: Zone[] = [
  { number: 0, color: BLUE, rpe: [1], min: 0, opti: 0, max: 10 },
  { number: 1, color: GREEN, rpe: [2], min: 20, opti: 30, max: 70 },
  { number: 2, color: GREEN, rpe: [3], min: 0, opti: 25, max: 30 },
  { number: 3, color: YELLOW, rpe: [4], min: 0, opti: 10, max: 25 },
  { number: 4, color: YELLOW, rpe: [5, 6], min: 0, opti: 10, max: 25 },
  { number: 5, color: RED, rpe: [7, 8], min: 5, opti: 15, max: 40 },
  { number: 6, color: RED, rpe: [9, 10], min: 0, opti: 5, max: 10 },
]

const getMinDurationForZone = (duration: number, zone: Zone) =>
  Math.floor((duration * zone.min) / 100)
const getOptiDurationForZone = (duration: number, zone: Zone) =>
  Math.floor((duration * zone.opti) / 100)
const getMaxDurationForZone = (duration: number, zone: Zone) =>
  Math.floor((duration * zone.max) / 100)

export { ZONES, getMinDurationForZone, getOptiDurationForZone, getMaxDurationForZone }
