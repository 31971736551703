import React, { ReactNode } from 'react'

import { UseCheckboxProps, useCheckbox } from '@chakra-ui/react'
import CircleSelectItem from './CircleSelectItem'

const CircleCheckboxItem = (props: UseCheckboxProps & {children: ReactNode}) => {
  const { state, getCheckboxProps, getInputProps } = useCheckbox(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <CircleSelectItem state={state} input={input} inputProps={checkbox}>
      {props.children}
    </CircleSelectItem>
  )
}

export default CircleCheckboxItem
