import React, { ReactNode } from 'react'
import { UseRadioProps, useRadio } from '@chakra-ui/react'
import CustomSelectItem from './CustomSelectItem'

const CustomRadioItem = (props: UseRadioProps & { children: ReactNode }) => {
  const { getInputProps, getRadioProps, state } = useRadio(props)

  const input = getInputProps()
  const radio = getRadioProps()

  return (
    <CustomSelectItem state={state} input={input} inputProps={radio}>
      {props.children}
    </CustomSelectItem>
  )
}

export default CustomRadioItem
