import React from 'react'

import { Center, Flex, Heading, useRadioGroup } from '@chakra-ui/react'
import OnboardingCard from '../OnboardingCard'

import CustomRadioItem from '../CustomRadioItem'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { DayStatus, prevDay } from '../../models/day'
import { isHard } from '../../models/training'
import { setPreferredLongDay } from '../../reducers/trainingSettingsReducer'
import { ONBOARDING_STEPS } from '../../App'
import NodeCircle from '../RadioCircle'
import CircleRadioItem from '../CircleRadioItem'

const PreferredDayLong = () => {
  const { value, getRadioProps } = useRadioGroup({})
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const week = useAppSelector((state) => state.trainingSettingsReducer.week)

  const handleNext = () => {
    const dayOfWeek = parseInt(value as string)
    if (dayOfWeek) {
      dispatch(setPreferredLongDay(dayOfWeek))
    }
    navigate('/onboarding/' + ONBOARDING_STEPS.strengthTraining.key)
  }

  return (
    <OnboardingCard
      title='An welchen Tagen dürfen wir dir bevorzugt ein langes Training planen?'
      onNextClicked={handleNext}
    >
      <Heading size='xs' fontStyle='italic' color='gray.300'>
        wenn du keine Präferenz hast, geh einfach weiter
      </Heading>
      <Center>
        <NodeCircle>
          {week.map((day) => {
            const dayOfWeek = day.dayOfWeek.toString()
            const radio = getRadioProps({ value: dayOfWeek })

            const dayOfHardTraining = week.find((day) => day.trainings.some((t) => isHard(t)))
            const dayOfPrefHardTraining = week.find(
              (day) => day.status === DayStatus.PREFERRED_HARD,
            )

            const disabled =
              day.status !== DayStatus.AVAILABLE ||
              (dayOfHardTraining &&
                (day.dayOfWeek === prevDay(dayOfHardTraining) ||
                  day.dayOfWeek === dayOfHardTraining.dayOfWeek)) ||
              (dayOfPrefHardTraining && day.dayOfWeek === prevDay(dayOfPrefHardTraining))

            return (
              <CircleRadioItem key={dayOfWeek} isDisabled={disabled} {...radio}>
                {day.short}
              </CircleRadioItem>
            )
          })}
        </NodeCircle>
      </Center>
    </OnboardingCard>
  )
}

export default PreferredDayLong
