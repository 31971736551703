import { Box, Center, useColorModeValue, useRadioGroup } from '@chakra-ui/react'
import React, { Children, ReactElement, ReactNode } from 'react'
import { WEEK } from '../models/week'
import CircleRadioItem from './CircleRadioItem'
import { SECONDARY_DARK, SECONDARY_LIGHT } from '../constants/colors'

type NodeCircleProps = {
  children: ReactElement | ReactElement[]
}

const NodeCircle = ({ children }: NodeCircleProps) => {
  const circleGraphRadius = 10
  const circleRadius = 3

  return (
    <Box
      position='relative'
      w={`${circleGraphRadius}rem`}
      h={`${circleGraphRadius}rem`}
      borderColor={useColorModeValue(SECONDARY_DARK, SECONDARY_LIGHT)}
      borderWidth='4px'
      borderRadius='50%'
      m={`${circleRadius / 2}rem`}
    >
      {Children.map(children, (child, index) => {
        const angle = Math.floor((index * 360) / Children.count(children) - 90)
        return (
          <Box
            position='absolute'
            w={`${circleRadius}rem`}
            h={`${circleRadius}rem`}
            top='50%'
            left='50%'
            transform={`rotate(${angle}deg) translate(${
              circleGraphRadius / 2
            }rem) rotate(${-angle}deg) translate(${-circleRadius / 2}rem, ${-circleRadius / 2}rem)`}
          >
            {child}
          </Box>
        )
      })}
    </Box>
  )
}

export default NodeCircle
