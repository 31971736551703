import React from 'react'

import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Stack,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'
import { MoonIcon, RepeatIcon, SunIcon } from '@chakra-ui/icons'

import LogoDark from './../assets/images/logoDark.png'
import LogoLight from './../assets/images/logoLight.png'
import { TERTIARY_DARK, TERTIARY_LIGHT } from '../constants/colors'
import { Link, useNavigate } from 'react-router-dom'
import { resetSettings } from '../reducers/trainingSettingsReducer'
import { useAppDispatch } from '../hooks'
import { ONBOARDING_STEPS } from '../App'

const NavBar = () => {
  const { colorMode, toggleColorMode } = useColorMode()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleRestart = () => {
    dispatch(resetSettings())
    navigate('/onboarding/' + ONBOARDING_STEPS.currentTimeForActivities.key)
  }

  return (
    <Box bg={useColorModeValue(TERTIARY_LIGHT, TERTIARY_DARK)}>
      <Container maxWidth='container.lg'>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <Link to='/'>
            <Image src={colorMode === 'light' ? LogoDark : LogoLight} w='24' objectFit='cover' />
          </Link>
          <Heading size='md'>Algorithmus | Proof of Concept</Heading>
          <Stack direction='row'>
            <Button onClick={toggleColorMode}>
              {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
            </Button>
            <Button onClick={handleRestart}>
              <RepeatIcon />
            </Button>
          </Stack>
        </Flex>
      </Container>
    </Box>
  )
}

export default NavBar
