export const PRIMARY_LIGHT = 'blue.500'
export const PRIMARY_DARK = 'blue.200'

export const SECONDARY_LIGHT = 'gray.700'
export const SECONDARY_DARK = 'gray.400'

export const TERTIARY_LIGHT = 'gray.300'
export const TERTIARY_DARK = 'gray.900'

export const ON_PRIMARY_LIGHT = 'black'
export const ON_PRIMARY_DARK = 'white'

export const ERROR_LIGHT = 'red.600'
export const ERROR_DARK = 'red.400'

export const RED = 'red.400'
export const YELLOW = 'yellow.400'
export const GREEN = 'green.400'
export const BLUE = 'blue.400'
export const GRAY = 'gray.400'
export const ORANGE = 'orange.400'
