import { Training, TrainingType, isHard } from './training'
import { Zone } from './zone'

enum DayStatus {
  NOTAVAILABLE = 'not available',
  AVAILABLE = 'available',
  PREFERRED_HARD = 'preferred hard',
  PREFERRED_LONG = 'preferred long',
  PLANNED = 'planned',
}

export type Day = {
  dayOfWeek: number
  short?: string
  long?: string
  trainings: Training[]
  status: DayStatus
}

const nextDay = (day: Day | number) => {
  if (typeof day === 'number') return (day + 1) % 7
  return (day.dayOfWeek + 1) % 7
}

const prevDay = (day: Day | number) => {
  if (typeof day === 'number') return (day + 6) % 7
  return (day.dayOfWeek + 6) % 7
}

const isNextDay = (day1: Day | number, day2: Day | number) => {
  if (typeof day2 === 'number') return nextDay(day1) === day2
  return nextDay(day1) === day2.dayOfWeek
}

const isPrevDay = (day1: Day | number, day2: Day | number) => {
  if (typeof day2 === 'number') return prevDay(day1) === day2
  return prevDay(day1) === day2.dayOfWeek
}

const areConsecutiveDays = (day1: Day | number, day2: Day | number) =>
  isNextDay(day1, day2) || isPrevDay(day1, day2)

const getDuration = (days: Day[]) =>
  days.reduce(
    (sum, day) => sum + day.trainings.reduce((s, t) => (t.duration ? s + t.duration : s), 0),
    0,
  )

const getDurationForType = (days: Day[], type: TrainingType) => {
  const filteredTrainings = days.map((day) => {
    return {
      ...day,
      trainings: day.trainings.filter(
        (t) => t.type === type || (type === TrainingType.HARD && isHard(t)),
      ),
    }
  })
  return getDuration(filteredTrainings)
}

const getDurationForZone = (days: Day[], zone: Zone) => {
  const filteredTrainings = days.map((day) => {
    return { ...day, trainings: day.trainings.filter((t) => t.zone === zone) }
  })
  return getDuration(filteredTrainings)
}

export {
  DayStatus,
  nextDay,
  prevDay,
  isNextDay,
  isPrevDay,
  areConsecutiveDays,
  getDuration,
  getDurationForType,
  getDurationForZone,
}
