import React, { useState } from 'react'

import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react'
import { generateTrainingsPlan } from '../services'
import { useAppSelector } from '../hooks'
import { Day, DayStatus, getDuration } from '../models/day'
import { BLUE, ERROR_DARK, ERROR_LIGHT, GRAY, GREEN, ORANGE, RED } from '../constants/colors'
import { Training, TrainingType } from '../models/training'
import { RepeatIcon } from '@chakra-ui/icons'

const Summary = () => {
  const currentTimeForActivities = useAppSelector(
    (state) => state.trainingSettingsReducer.currentTimeForActivities,
  )
  const maxTimeForActivities = useAppSelector(
    (state) => state.trainingSettingsReducer.maxTimeForActivities,
  )

  const trainingSettings = useAppSelector((state) => state.trainingSettingsReducer)
  const week = trainingSettings.week

  const [plan, setPlan] = useState(generateTrainingsPlan(trainingSettings))

  const handleRegenerate = () => {
    setPlan(generateTrainingsPlan(trainingSettings))
  }

  const planDuration = plan ? getDuration(plan): 0

  return (
    <VStack align='flex-start' w='full'>
      <Heading>Eingabe</Heading>
      <Flex justifyContent='space-between' w='full'>
        <Text>ich bin aktuell {currentTimeForActivities} min aktiv</Text>
        <Text>ich möchte maximal {maxTimeForActivities} min Bewegung machen</Text>
      </Flex>
      <Flex justifyContent='stretch' w='full' gap='2'>
        {week.map((day) => (
          <SummaryCard day={day} key={day.dayOfWeek} />
        ))}
      </Flex>
      <Flex alignItems='flex-end' justifyContent='space-between' w='full'>
        <Heading mt='20'>Trainingsplan</Heading>
        <Button onClick={handleRegenerate} leftIcon={<RepeatIcon />}>
          neu generieren
        </Button>
      </Flex>
      <Text>es wurden {planDuration} min verplant</Text>
      {plan ? (
        <Flex justifyContent='stretch' w='full' gap='2'>
          {plan.map((day) => (
            <SummaryCard day={day} key={day.dayOfWeek} />
          ))}
        </Flex>
      ) : (
        <Heading color={useColorModeValue(ERROR_LIGHT, ERROR_DARK)}>
          Es ist ein fehler in der Planerstellung passiert!
        </Heading>
      )}
    </VStack>
  )
}

type SummaryCardInputProps = {
  day: Day
}

const SummaryCard = ({ day }: SummaryCardInputProps) => {
  let bgColor
  switch (day.status) {
    case DayStatus.AVAILABLE:
    case DayStatus.PLANNED:
      bgColor = BLUE
      break
    case DayStatus.PREFERRED_HARD:
      bgColor = RED
      break
    case DayStatus.PREFERRED_LONG:
      bgColor = GREEN
      break
    default:
      bgColor = GRAY
  }

  return (
    <Card w='full' shadow='lg' bg={bgColor} borderRadius='lg'>
      <CardHeader textAlign='center'>
        <Heading size='sm'>{day.short}</Heading>
      </CardHeader>
      <CardBody p='2' pt='0' justifyContent='flex-end'>
        <Flex direction='column' gap='2'>
          {day.trainings.map((training, index) => (
            <TrainingCard key={index} training={training} />
          ))}
        </Flex>
      </CardBody>
    </Card>
  )
}

type TrainingCardType = {
  training: Training
}

const TrainingCard = ({ training }: TrainingCardType) => {
  const isStrength = training.type === TrainingType.STRENGTH
  let bgColor = GRAY
  if (isStrength) bgColor = ORANGE
  else if (training.zone) bgColor = training.zone.color

  const interval = training.interval

  return (
    <Box bg={bgColor} w='full' borderRadius='md' px='3' py='2'>
      <Flex direction='column'>
        <Flex justifyContent='space-between' overflow='hidden'>
          <Text fontWeight='bold'>{training.type}</Text>
          {isStrength ? <Text>💪</Text> : training.sport?.emoji}
        </Flex>
        {training.duration ? (
          <HStack justifyContent="space-between">
            <Text>{training.duration} min</Text>
            <Text fontWeight="bold">{training.zone?.number}</Text>
          </HStack>
        ) : null}
        {interval ? (
          <>
            <Text>{interval!.splitSeconds}s/{interval!.splitSeconds}s</Text>
            <Text>{interval!.repetitions}x</Text>
            {interval!.rounds > 1 ?
            <>
            <Text>{interval!.rounds} Runden</Text>
            <Text>{interval!.roundBreak}min Pause</Text>
            </>: null}
          </>
        ) : null}
      </Flex>
    </Box>
  )
}

export default Summary
