import React from 'react'

import { Button, Center, Flex, Heading, useCheckboxGroup } from '@chakra-ui/react'
import OnboardingCard from '../OnboardingCard'
import CustomCheckboxItem from '../CustomCheckboxItem'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../hooks'
import { isHard } from '../../models/training'
import { areConsecutiveDays, isNextDay, nextDay, prevDay } from '../../models/day'
import { WEEK } from '../../models/week'
import { useDispatch } from 'react-redux'
import {
  setAvailableDays,
  setPreferredHardDay,
  setPreferredLongDay,
} from '../../reducers/trainingSettingsReducer'
import { ONBOARDING_STEPS } from '../../App'
import NodeCircle from '../RadioCircle'
import CircleCheckboxItem from '../CircleCheckboxItem'

const AvailableDays = () => {
  const { value, getCheckboxProps, setValue } = useCheckboxGroup()
  console.log("🚀 ~ file: AvailableDays.tsx:23 ~ AvailableDays ~ value:", value)

  const navigate = useNavigate()
  const week = useAppSelector((state) => state.trainingSettingsReducer.week)

  const dispatch = useDispatch()

  const handleNext = () => {
    const days = value.map((v) => parseInt(v as string))
    if (days.length < 2) return

    const dayOfHardTraining = week.find((day) => day.trainings.some((t) => isHard(t)))
    if (days.length === 2 && areConsecutiveDays(days[0], days[1])) {
      const hardDay = isNextDay(days[0], days[1]) ? days[0] : days[1]
      const longDay = isNextDay(days[0], days[1]) ? days[1] : days[0]
      dispatch(setPreferredLongDay(longDay))
      if (!dayOfHardTraining) dispatch(setPreferredHardDay(hardDay))
      return navigate('/onboarding/' + ONBOARDING_STEPS.strengthTraining.key)
    }

    dispatch(setAvailableDays(days))
    const longDays = days.filter(
      (day) =>
        dayOfHardTraining &&
        day !== dayOfHardTraining.dayOfWeek &&
        day !== prevDay(dayOfHardTraining),
    )

    if (longDays.length === 1) {
      dispatch(setPreferredLongDay(longDays[0]))
      return navigate('/onboarding/' + ONBOARDING_STEPS.strengthTraining.key)
    } else if (dayOfHardTraining) {
      return navigate('/onboarding/' + ONBOARDING_STEPS.prefDayForLong.key)
    }
    navigate('/onboarding/' + ONBOARDING_STEPS.prefDayForHard.key)
  }

  return (
    <OnboardingCard
      title='An welchen Tagen dürfen wir dir ein Training planen?'
      onNextClicked={handleNext}
      isNextDisabled={value.length < 2}
    >
      <Heading size='sm'>mindestens 2 Tage</Heading>
      <Center>
        <NodeCircle>
          {week.map((day) => {
            const isHardTraining = day.trainings.some((training) => isHard(training))
            const dayOfWeek = day.dayOfWeek.toString()

            // FIX if all is pressed and then unselect all: day before is selectable
            // maybe radio buttons are now unselectable

            const nextHardAndOnly =
            week[nextDay(day)].trainings.some((training) => isHard(training)) &&
            (value.length === 1 || (value.length === 2 && value.includes(dayOfWeek)))

            if (isHardTraining && !value.includes(dayOfWeek)) {
              setValue([...value, dayOfWeek])
            } else if (nextHardAndOnly && value.includes(dayOfWeek)) {
              setValue(value.filter((v) => v !== dayOfWeek))
            }

            const disabled = isHardTraining || nextHardAndOnly
            const checkbox = getCheckboxProps({ value: dayOfWeek })

            return (
              <CircleCheckboxItem key={day.dayOfWeek} isDisabled={disabled} {...checkbox}>
                {day.short}
              </CircleCheckboxItem>
            )
          })}
        </NodeCircle>
      </Center>
      <Button
        onClick={() => setValue(WEEK.map((day) => day.dayOfWeek.toString()))}
        variant='outline'
        colorScheme='blue'
      >
        Alle
      </Button>
    </OnboardingCard>
  )
}

export default AvailableDays
