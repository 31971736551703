import React, { ReactNode } from 'react'
import {
  Box,
  Center,
  CheckboxState,
  UseRadioGroupReturn,
  useColorModeValue,
  useTheme,
} from '@chakra-ui/react'
import {
  ON_PRIMARY_DARK,
  ON_PRIMARY_LIGHT,
  PRIMARY_DARK,
  PRIMARY_LIGHT,
  SECONDARY_DARK,
  SECONDARY_LIGHT,
} from '../constants/colors'

type CircleSelectItemProps = {
  state: CheckboxState
  input: any
  inputProps: any
  children: ReactNode
}

const CircleSelectItem = ({ state, input, inputProps, children }: CircleSelectItemProps) => {
  const handleSelect = () => {
    if (input.checked && input.onChange) {
      (input.onChange as UseRadioGroupReturn['onChange'])('')
    }
  }

  const theme = useTheme()

  return (
    <Box as='label'>
      <input {...input} onClick={handleSelect} />
      <Center
        {...inputProps}
        cursor='pointer'
        borderRadius='50%'
        _checked={{
          bg: useColorModeValue(PRIMARY_LIGHT, PRIMARY_DARK),
          borderWidth: 0,
        }}
        _disabled={{
          bg: useColorModeValue(
            state.isChecked ? PRIMARY_LIGHT : SECONDARY_LIGHT,
            state.isChecked ? PRIMARY_DARK : SECONDARY_DARK,
          ),
          cursor: 'not-allowed',
        }}
        borderColor={useColorModeValue(SECONDARY_DARK, SECONDARY_LIGHT)}
        borderWidth="4px"
        textAlign='center'
        w='full'
        h='full'
        color={useColorModeValue(ON_PRIMARY_LIGHT, ON_PRIMARY_DARK)}
        bg={theme.__cssMap['colors.chakra-body-bg'].value}
      >
        {children}
      </Center>
    </Box>
  )
}

export default CircleSelectItem
