import React from 'react'

import {
  Container,
  Step,
  StepIcon,
  StepIndicator,
  StepSeparator,
  StepStatus,
  Stepper,
} from '@chakra-ui/react'

type ProgressTrackerProps = {
  length: number
  step: number
}

const ProgressTracker = ({ length, step }: ProgressTrackerProps) => {
  return (
    <Container py='4'>
      <Stepper index={step} size='sm' gap='0'>
        {Array.from({ length }, (_, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus complete={<StepIcon />} />
            </StepIndicator>
            <StepSeparator />
          </Step>
        ))}
      </Stepper>
    </Container>
  )
}

export default ProgressTracker
