export const WARM_UP_DURATION = 10
export const COOL_DOWN_DURATION = WARM_UP_DURATION

export const MINIMUM_LONG_T_DURATION = 75
export const MAXIMUM_LONG_T_DURATION = 240
export const MINIMUM_HARD_T_DURATION = 15
export const MAXIMUM_HARD_T_DURATION = 90
export const MINIMUM_NORMAL_T_DURATION = 15
export const MAXIMUM_NORMAL_T_DURATION = 75

export const MINIMUM_T_DURATION = 150
export const MAXIMUM_T_DURATION = 1000

export const REMAINING_DURATION_TO_OPTIMUM = 15
export const RANGE_FOR_INTERVAL_DURATION = 0.15

export const MAXIMUM_FOR_DAYS = [0, 180, 360, 480, 480, 480, 1000]

export const NEW_DURATION = {
  INCREASE_PER_WEEK: 0.1,
  DAYS_TO_CONSIDER: 28,
}
