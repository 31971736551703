export type Sport = {
  id: number
  name: string
  emoji?: string
}

const SPORTS: Sport[] = [
  { id: 1, name: 'Fussball', emoji: '⚽️' },
  { id: 2, name: 'Laufen', emoji: '🏃' },
  { id: 3, name: 'Fahrrad', emoji: '🚲' },
  { id: 4, name: 'Gehen', emoji: '🚶' },
]

export { SPORTS }
