import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Training, TrainingSettings } from '../models/training'
import { Day, DayStatus } from '../models/day'
import { WEEK } from '../models/week'

const initialState: TrainingSettings = {
  currentTimeForActivities: 150,
  maxTimeForActivities: 150,
  week: WEEK,
}

const trainingSettingsSlice = createSlice({
  name: 'trainingSettings',
  initialState,
  reducers: {
    setTrainingSettings(_, action) {
      return action.payload
    },
    setCurrentTime(state, action: PayloadAction<number>) {
      return { ...state, currentTimeForActivities: action.payload }
    },
    setMaxTime(state, action: PayloadAction<number>) {
      return { ...state, maxTimeForActivities: action.payload }
    },
    setTraining(state, action: PayloadAction<{ training: Training; dayOfWeek: number }>) {
      const training = action.payload.training
      const dayOfWeek = action.payload.dayOfWeek
      return {
        ...state,
        week: state.week.map((day) =>
          day.dayOfWeek === dayOfWeek ? { ...day, trainings: [...day.trainings, training] } : day,
        ),
      }
    },
    setAvailableDays(state, action: PayloadAction<number[]>) {
      const days = action.payload
      return {
        ...state,
        week: state.week.map((day) =>
          days.includes(day.dayOfWeek) ? { ...day, status: DayStatus.AVAILABLE } : day,
        ),
      }
    },
    setAvailableDay(state, action: PayloadAction<number>) {
      const dayOfWeek = action.payload
      return {
        ...state,
        week: state.week.map((day) =>
          day.dayOfWeek === dayOfWeek ? { ...day, status: DayStatus.AVAILABLE } : day,
        ),
      }
    },
    setPreferredHardDay(state, action: PayloadAction<number>) {
      const dayNumber = action.payload
      return {
        ...state,
        week: state.week.map((day) =>
          dayNumber === day.dayOfWeek ? { ...day, status: DayStatus.PREFERRED_HARD } : day,
        ),
      }
    },
    setPreferredLongDay(state, action: PayloadAction<number>) {
      const dayNumber = action.payload
      return {
        ...state,
        week: state.week.map((day) =>
          dayNumber === day.dayOfWeek ? { ...day, status: DayStatus.PREFERRED_LONG } : day,
        ),
      }
    },
  },
})

export const {
  setTrainingSettings,
  setCurrentTime,
  setMaxTime,
  setTraining,
  setAvailableDays,
  setAvailableDay,
  setPreferredHardDay,
  setPreferredLongDay,
} = trainingSettingsSlice.actions
export default trainingSettingsSlice.reducer

export const resetSettings = () => {
  return (dispatch: Dispatch) => {
    dispatch(setTrainingSettings(initialState))
  }
}

// export const initializeUser = () => {
//   return async (dispatch) => {
//     const loggedUserJSON = window.localStorage.getItem(STORAGE_KEY)
//     if (loggedUserJSON) {
//       const user = JSON.parse(loggedUserJSON)
//       dispatch(setUser(user))
//     }
//   }
// }

// export const login = (username, password) => {
//   return async (dispatch) => {
//     try {
//       const user = await loginService.login({
//         username,
//         password,
//       })
//       localStorage.setItem(STORAGE_KEY, JSON.stringify(user))
//       dispatch(setUser(user))
//       dispatch(displayNotification(`${user.name} logged in!`, 'info', 5000))
//     } catch (error) {
//       dispatch(displayNotification('wrong username/password', 'alert', 5000))
//     }
//   }
// }
