import { Day } from './day'
import { IntervalTraining } from './interval'
import { Sport } from './sport'
import { Zone } from './zone'

enum TrainingType {
  INTERVAL = 'Intervall',
  INTERVAL_WARMUP = 'Intervall aufwärmen',
  INTERVAL_COOLDOWN = 'Intervall abkühlen',
  GAME = 'Spiel',
  LONG = 'Lang',
  HARD = 'Hart',
  STRENGTH = 'Kraft',
  NORMAL = 'Normal',
  MIX = 'Mix',
  MIX_1 = 'Mix1',
  MIX_2 = 'Mix2',
}

export type Training = {
  type: TrainingType
  sport?: Sport
  duration?: number
  zone?: Zone
  interval?: IntervalTraining
}

export type TrainingSettings = {
  currentTimeForActivities: number,
  maxTimeForActivities: number,
  week: Day[]
}

const isHard = (training: Training) =>
  training.type === TrainingType.GAME ||
  training.type === TrainingType.INTERVAL ||
  training.type === TrainingType.HARD
const isLong = (training: Training) => training.type === TrainingType.LONG

const isStrength = (training: Training) => training.type === TrainingType.STRENGTH

export { TrainingType, isHard, isLong, isStrength }
