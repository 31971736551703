import React, { ReactNode } from 'react'
import { Box, Button, Flex, Heading } from '@chakra-ui/react'

type OnboardingCardProps = {
  title: string
  onNextClicked: () => void
  isNextDisabled?: boolean
  children: ReactNode
}

const OnboardingCard = ({ title, onNextClicked, isNextDisabled, children }: OnboardingCardProps) => {
  return (
    <Box borderWidth='2px' borderRadius='lg' w='md' h='xl' p='4'>
      <Flex direction='column' justifyContent='space-between' h='full'>
        <Heading size='md'>{title}</Heading>
        {children}
        <Button colorScheme='blue' alignSelf='flex-end' onClick={() => onNextClicked()} isDisabled={isNextDisabled}>
          Next
        </Button>
      </Flex>
    </Box>
  )
}

export default OnboardingCard
