import React, { ReactNode } from 'react'
import { Box, CheckboxState, UseRadioGroupReturn, useColorModeValue } from '@chakra-ui/react'
import {
  ON_PRIMARY_DARK,
  ON_PRIMARY_LIGHT,
  PRIMARY_DARK,
  PRIMARY_LIGHT,
  SECONDARY_DARK,
  SECONDARY_LIGHT,
} from '../constants/colors'

type CustomSelectItemProps = {
  state: CheckboxState
  input: any
  inputProps: any
  children: ReactNode
}

const CustomSelectItem = ({ state, input, inputProps, children }: CustomSelectItemProps) => {


  const handleSelect = () => {
    if (input.checked && input.onChange) {
      (input.onChange as UseRadioGroupReturn['onChange'])('')
    }
  }

  return (
    <Box as='label' flex='1'>
      <input {...input} onClick={handleSelect}/>
      <Box
        {...inputProps}
        cursor='pointer'
        borderWidth='1px'
        borderRadius='md'
        boxShadow='md'
        _checked={{
          bg: useColorModeValue(PRIMARY_LIGHT, PRIMARY_DARK),
          color: useColorModeValue(ON_PRIMARY_LIGHT, ON_PRIMARY_DARK),
          borderWidth: 0,
        }}
        _disabled={{
          bg: useColorModeValue(
            state.isChecked ? PRIMARY_LIGHT : SECONDARY_LIGHT,
            state.isChecked ? PRIMARY_DARK : SECONDARY_DARK,
          ),
          color: useColorModeValue(ON_PRIMARY_LIGHT, ON_PRIMARY_DARK),
          cursor: 'not-allowed',
        }}
        px={5}
        py={3}
        textAlign='center'
      >
        {children}
      </Box>
    </Box>
  )
}

export default CustomSelectItem
