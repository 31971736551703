import { Button, Flex, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import pjson from './../../package.json'
import { ONBOARDING_STEPS } from '../App'

const Home = () => {
  return (
    <Flex direction='column' mt='10'>
      <Heading>Algorithmus für Straide-App</Heading>
      <Flex justifyContent='space-between' alignItems='flex-end'>
        <Heading mt='2' size='md'>
          Proof of Concept
        </Heading>
        <Text mt='4' color='gray.400' fontWeight='bold'>
          v{pjson.version}
        </Text>
      </Flex>
      <Link to={'/onboarding/' + ONBOARDING_STEPS.currentTimeForActivities.key}>
        <Button mt='12' colorScheme='blue' w='full' h='12'>
          <Heading size='md'>Algorithmus Testen</Heading>
        </Button>
      </Link>
    </Flex>
  )
}

export default Home
