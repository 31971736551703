import React, { useState } from 'react'

import {
  Center,
  Flex,
  HStack,
  Heading,
  InputGroup,
  InputRightAddon,
  NumberInput,
  NumberInputField,
  Select,
  VStack,
  useRadioGroup,
} from '@chakra-ui/react'

import OnboardingCard from '../OnboardingCard'
import CustomRadioItem from '../CustomRadioItem'
import { useNavigate } from 'react-router-dom'
import { WEEK } from '../../models/week'
import { MAXIMUM_HARD_T_DURATION, MINIMUM_HARD_T_DURATION } from '../../constants/durations'
import { useAppDispatch, useAppSelector } from '../../hooks'
import _ from 'lodash'
import {
  ZONES,
  getMaxDurationForZone,
  getMinDurationForZone,
  getOptiDurationForZone,
} from '../../models/zone'
import { setAvailableDay, setTraining } from '../../reducers/trainingSettingsReducer'
import { TrainingType } from '../../models/training'
import { SPORTS, Sport } from '../../models/sport'
import { roundToMultipleOf5 } from '../../services'
import { ONBOARDING_STEPS } from '../../App'
import NodeCircle from '../RadioCircle'
import CircleRadioItem from '../CircleRadioItem'

const PlannedHardTraining = () => {
  const { getRootProps, getRadioProps, value } = useRadioGroup({
    onChange: (nextValue) => {
      if (nextValue && !duration) {
        const optiDuration = roundToMultipleOf5(
          getOptiDurationForZone(currentTimeForActivities, ZONES[5]),
        )
        setDuration(optiDuration)
      } else if (!nextValue) {
        setDuration(undefined)
      }
    },
  })
  const group = getRootProps()
  const [duration, setDuration] = useState<number | undefined>()
  const [sportID, setSportID] = useState(SPORTS[0].id)

  const navigate = useNavigate()
  const currentTimeForActivities = useAppSelector(
    (state) => state.trainingSettingsReducer.currentTimeForActivities,
  )
  const dispatch = useAppDispatch()

  const minTime = _.clamp(
    getMinDurationForZone(currentTimeForActivities, ZONES[5]),
    MINIMUM_HARD_T_DURATION,
    MAXIMUM_HARD_T_DURATION,
  )

  const maxTime = _.clamp(
    getMaxDurationForZone(currentTimeForActivities, ZONES[5]),
    MINIMUM_HARD_T_DURATION,
    MAXIMUM_HARD_T_DURATION,
  )

  const handleNext = () => {
    if (value && duration) {
      const dayOfWeek = parseInt(value as string)
      const training = {
        duration: duration!,
        type: TrainingType.GAME,
        zone: ZONES[5],
        sport: SPORTS.find((s) => s.id === sportID),
      }
      dispatch(setTraining({ training, dayOfWeek }))
      dispatch(setAvailableDay(dayOfWeek))
    }
    return navigate('/onboarding/' + ONBOARDING_STEPS.availableDays.key)
  }

  return (
    <OnboardingCard
      title={'Geplante Spielsport Einheit'}
      onNextClicked={handleNext}
      isNextDisabled={value && !duration ? true : false}
    >
      <VStack align='flex-start'>
        <Heading size='xs' fontStyle='italic' color='gray.300'>
          wenn du keinen Spielsport geplant hast, geh einfach weiter
        </Heading>
        <Heading size='sm'>an welchem Tag</Heading>

        <Center w='full'>
          <NodeCircle>
            {WEEK.map((day) => {
              const radio = getRadioProps({
                value: day.dayOfWeek.toString(),
              })
              return (
                <CircleRadioItem key={day.dayOfWeek} {...radio}>
                  {day.short}
                </CircleRadioItem>
              )
            })}
          </NodeCircle>
        </Center>
        <HStack>
          <VStack align='flex-start' flex='1'>
            <Heading size='sm'>und wie lang</Heading>
            <InputGroup>
              <NumberInput
                value={isNaN(duration!) ? '' : duration}
                min={minTime}
                max={maxTime}
                onChange={(val) => setDuration(parseInt(val))}
                isDisabled={!duration && !value}
                placeholder='Dauer'
              >
                <NumberInputField borderRightRadius='0' />
              </NumberInput>
              <InputRightAddon>min</InputRightAddon>
            </InputGroup>
          </VStack>

          <VStack align='flex-start' flex='1'>
            <Heading size='sm'>und was</Heading>
            <Select
              isDisabled={!value}
              onChange={(e) => setSportID(parseInt(e.target.value))}
              value={sportID}
            >
              <optgroup label='Sportarten'>
                {SPORTS.map((sport) => (
                  <option value={sport.id} key={sport.id}>
                    {sport.emoji + ' ' + sport.name}
                  </option>
                ))}
              </optgroup>
            </Select>
          </VStack>
        </HStack>
      </VStack>
    </OnboardingCard>
  )
}

export default PlannedHardTraining
