import { SPORTS } from './sport'
import { ZONES } from './zone'
import { Training, TrainingType } from './training'
import { RANGE_FOR_INTERVAL_DURATION } from '../constants/durations'

export type IntervalTraining = {
  weightFactor: number
  splitSeconds: number
  roundBreak: number
  repetitions: number
  rounds: number
  duration?: number
}

const INTERVAL_TRAININGS: IntervalTraining[] = [
  { weightFactor: 2, splitSeconds: 45, roundBreak: 0, repetitions: 10, rounds: 1 },
  { weightFactor: 4, splitSeconds: 30, roundBreak: 0, repetitions: 15, rounds: 1 },
  { weightFactor: 1, splitSeconds: 15, roundBreak: 0, repetitions: 20, rounds: 1 },
  { weightFactor: 1, splitSeconds: 60, roundBreak: 0, repetitions: 10, rounds: 1 },
  { weightFactor: 2, splitSeconds: 40, roundBreak: 0, repetitions: 15, rounds: 1 },
  { weightFactor: 4, splitSeconds: 30, roundBreak: 0, repetitions: 20, rounds: 1 },
  { weightFactor: 4, splitSeconds: 20, roundBreak: 0, repetitions: 30, rounds: 1 },
  { weightFactor: 2, splitSeconds: 45, roundBreak: 5, repetitions: 10, rounds: 2 },
  { weightFactor: 4, splitSeconds: 30, roundBreak: 5, repetitions: 15, rounds: 2 },
  { weightFactor: 1, splitSeconds: 15, roundBreak: 5, repetitions: 20, rounds: 2 },
  { weightFactor: 4, splitSeconds: 30, roundBreak: 0, repetitions: 25, rounds: 1 },
  { weightFactor: 1, splitSeconds: 15, roundBreak: 0, repetitions: 50, rounds: 1 },
  { weightFactor: 1, splitSeconds: 60, roundBreak: 0, repetitions: 15, rounds: 1 },
  { weightFactor: 2, splitSeconds: 45, roundBreak: 0, repetitions: 20, rounds: 1 },
  { weightFactor: 4, splitSeconds: 30, roundBreak: 0, repetitions: 30, rounds: 1 },
  { weightFactor: 4, splitSeconds: 20, roundBreak: 0, repetitions: 45, rounds: 1 },
  { weightFactor: 1, splitSeconds: 15, roundBreak: 0, repetitions: 60, rounds: 1 },
  { weightFactor: 1, splitSeconds: 60, roundBreak: 5, repetitions: 10, rounds: 2 },
  { weightFactor: 2, splitSeconds: 40, roundBreak: 5, repetitions: 15, rounds: 2 },
  { weightFactor: 4, splitSeconds: 30, roundBreak: 5, repetitions: 20, rounds: 2 },
  { weightFactor: 4, splitSeconds: 20, roundBreak: 5, repetitions: 30, rounds: 2 },
  { weightFactor: 2, splitSeconds: 45, roundBreak: 5, repetitions: 10, rounds: 3 },
  { weightFactor: 4, splitSeconds: 30, roundBreak: 5, repetitions: 15, rounds: 3 },
  { weightFactor: 1, splitSeconds: 15, roundBreak: 5, repetitions: 20, rounds: 3 },
  { weightFactor: 4, splitSeconds: 30, roundBreak: 5, repetitions: 25, rounds: 2 },
  { weightFactor: 1, splitSeconds: 15, roundBreak: 5, repetitions: 50, rounds: 2 },
  { weightFactor: 1, splitSeconds: 60, roundBreak: 5, repetitions: 10, rounds: 3 },
  { weightFactor: 2, splitSeconds: 40, roundBreak: 5, repetitions: 15, rounds: 3 },
  { weightFactor: 4, splitSeconds: 30, roundBreak: 5, repetitions: 20, rounds: 3 },
  { weightFactor: 4, splitSeconds: 20, roundBreak: 5, repetitions: 30, rounds: 3 },
  { weightFactor: 1, splitSeconds: 60, roundBreak: 5, repetitions: 15, rounds: 2 },
  { weightFactor: 2, splitSeconds: 45, roundBreak: 5, repetitions: 20, rounds: 2 },
  { weightFactor: 4, splitSeconds: 30, roundBreak: 5, repetitions: 30, rounds: 2 },
  { weightFactor: 4, splitSeconds: 20, roundBreak: 5, repetitions: 45, rounds: 2 },
  { weightFactor: 1, splitSeconds: 15, roundBreak: 5, repetitions: 60, rounds: 2 },
  { weightFactor: 4, splitSeconds: 30, roundBreak: 5, repetitions: 25, rounds: 3 },
  { weightFactor: 1, splitSeconds: 15, roundBreak: 5, repetitions: 50, rounds: 3 },
  { weightFactor: 1, splitSeconds: 60, roundBreak: 5, repetitions: 15, rounds: 3 },
  { weightFactor: 2, splitSeconds: 45, roundBreak: 5, repetitions: 20, rounds: 3 },
  { weightFactor: 4, splitSeconds: 40, roundBreak: 5, repetitions: 30, rounds: 3 },
  { weightFactor: 4, splitSeconds: 30, roundBreak: 5, repetitions: 45, rounds: 3 },
  { weightFactor: 1, splitSeconds: 15, roundBreak: 5, repetitions: 60, rounds: 3 },
]

const IntervalTrainingsWithDuration = INTERVAL_TRAININGS.map((training) => {
  const repetitions = training.repetitions * 2 - 1 // stop after load
  const split = training.splitSeconds / 60
  const duration = Math.floor(
    (split * repetitions + training.roundBreak) * training.rounds - training.roundBreak, // no break at end
  )
  return { ...training, duration }
})

const selectRandomInterval = (intervals: IntervalTraining[]) => {
  const totalWeight = intervals.reduce((total, item) => total + item.weightFactor, 0)

  const randomValue = Math.random() * totalWeight

  let cumulativeWeight = 0
  for (const item of intervals) {
    cumulativeWeight += item.weightFactor
    if (randomValue <= cumulativeWeight) {
      return item
    }
  }

  return null
}

export const randomIntervalTraining = (targetDuration: number): Training | null => {
  const possibleIntervals = IntervalTrainingsWithDuration.filter(
    (training) =>
      training.duration > targetDuration * (1 - RANGE_FOR_INTERVAL_DURATION) &&
      training.duration < targetDuration * (1 + RANGE_FOR_INTERVAL_DURATION),
  )
  const randomInterval = selectRandomInterval(possibleIntervals)
  if (!randomInterval) return null
  return {
    type: TrainingType.INTERVAL,
    sport: SPORTS[1],
    duration: randomInterval.duration,
    zone: ZONES[5],
    interval: randomInterval,
  }
}
