import _ from 'lodash'
import {
  Training,
  TrainingSettings,
  TrainingType,
  isHard,
  isLong,
  isStrength,
} from './models/training'
import {
  Day,
  DayStatus,
  areConsecutiveDays,
  getDuration,
  getDurationForType,
  getDurationForZone,
  nextDay,
  prevDay,
} from './models/day'
import { randomIntervalTraining } from './models/interval'
import {
  ZONES,
  getMaxDurationForZone,
  getMinDurationForZone,
  getOptiDurationForZone,
} from './models/zone'
import {
  COOL_DOWN_DURATION,
  MAXIMUM_HARD_T_DURATION,
  MAXIMUM_LONG_T_DURATION,
  MAXIMUM_NORMAL_T_DURATION,
  MAXIMUM_T_DURATION,
  MINIMUM_HARD_T_DURATION,
  MINIMUM_LONG_T_DURATION,
  MINIMUM_NORMAL_T_DURATION,
  MINIMUM_T_DURATION,
  REMAINING_DURATION_TO_OPTIMUM,
  WARM_UP_DURATION,
} from './constants/durations'
import { SPORTS } from './models/sport'
import { getAvailableDays } from './models/week'

const roundToMultipleOf5 = (number: number) => Math.round(number / 5) * 5

const getDurationForNewWeek = (trainingSettings: TrainingSettings) => {
  let newDuration = _.clamp(
    trainingSettings.currentTimeForActivities,
    MINIMUM_T_DURATION,
    MAXIMUM_T_DURATION,
  )
  newDuration = _.clamp(newDuration, MINIMUM_T_DURATION, trainingSettings.maxTimeForActivities)
  return newDuration
}

const appendTrainingToWeek = (
  week: Day[],
  day: number | Day,
  training: Training | Training[],
): Day[] => {
  if (typeof day !== 'number') day = day.dayOfWeek
  if (!Array.isArray(training)) training = [training]
  week[day] = {
    ...week[day],
    trainings: [...week[day].trainings, ...training],
  }
  return week
}

const getRandomDayForHardTraining = (week: Day[]): Day | null => {
  const preferredDay = week.find((day) => day.status === DayStatus.PREFERRED_HARD)
  if (preferredDay) return preferredDay

  // get available days and filter if
  // * prev is not long
  // * prev is not preferred long
  // * today is no hard training
  // * next is no hard training
  // * prev is no hard training
  // * today is no long training
  const availableDays = getAvailableDays(week).filter((day) => {
    const _prevDay = week[prevDay(day)]
    const _nextDay = week[nextDay(day)]
    const prevIsLong = _prevDay.trainings.some((t) => isLong(t))
    const prevIsPrefLong = _prevDay.status === DayStatus.PREFERRED_LONG
    const hardTrainingToday = day.trainings.some((t) => isHard(t))
    const prevIsHard = _prevDay.trainings.some((t) => isHard(t))
    const nextIsHard = _nextDay.trainings.some((t) => isHard(t))
    const longTrainingToday = day.trainings.some((t) => isLong(t))
    const prefLongTrainingToday = day.status === DayStatus.PREFERRED_LONG
    const strengthTrainingToday = day.trainings.some((t) => isStrength(t))

    return (
      !prevIsLong &&
      !prevIsPrefLong &&
      !hardTrainingToday &&
      !prevIsHard &&
      !nextIsHard &&
      !longTrainingToday &&
      !strengthTrainingToday &&
      !prefLongTrainingToday
    )
  })

  if (availableDays.length === 0) return null

  // if only two days available and no long training planned and consecutiv take the first day
  const longTrainingPlanned = week.some(
    (day) => day.trainings.some((t) => isLong(t)) || day.status === DayStatus.PREFERRED_LONG,
  )

  if (
    !longTrainingPlanned &&
    availableDays.length === 2 &&
    areConsecutiveDays(availableDays[0], availableDays[1])
  ) {
    return availableDays[0]
  }

  return _.sample(availableDays)! // otherwise get random day
}

const getRandomDayForLongTraining = (week: Day[]): Day | null => {
  const preferredDay = week.find((day) => day.status === DayStatus.PREFERRED_LONG)
  if (preferredDay) return preferredDay

  const availableDays = getAvailableDays(week).filter((day) => {
    const _nextDay = week[nextDay(day)]
    const _prevDay = week[prevDay(day)]
    const nextIsHard = _nextDay.trainings.some((t) => isHard(t))
    const nextIsPrefHard = _nextDay.status === DayStatus.PREFERRED_HARD
    const longTrainingToday = day.trainings.some((t) => isLong(t))
    const prevIsLong = _prevDay.trainings.some((t) => isLong(t))
    const nextIsLong = _nextDay.trainings.some((t) => isLong(t))
    const hardTrainingToday = day.trainings.some((t) => isHard(t))

    return (
      !nextIsHard &&
      !nextIsPrefHard &&
      !longTrainingToday &&
      !prevIsLong &&
      !nextIsLong &&
      !hardTrainingToday
    )
  })

  if (availableDays.length === 0) return null

  // if only two days available and no hard training planned and consecutiv take the first day
  const hardTrainingPlanned = week.some(
    (day) => day.trainings.some((t) => isHard(t)) || day.status === DayStatus.PREFERRED_HARD,
  )

  if (
    !hardTrainingPlanned &&
    availableDays.length === 2 &&
    areConsecutiveDays(availableDays[0], availableDays[1])
  ) {
    return availableDays[1]
  }

  return _.sample(availableDays)! // otherwise get random day
}

const generateTrainingsPlan = (trainingSettings: TrainingSettings): Day[] | null => {
  const week = trainingSettings.week

  // eslint-disable-next-line prefer-const
  let newPlan = _.clone([...week])

  let hardTrainingDay = week.find((day) => day.trainings.some((t) => isHard(t)))
  // If no hard training is planned

  const hardTrainingDuration = getDurationForType(newPlan, TrainingType.HARD)
  let remainingHardTrainingDurationToOptimum =
    getOptiDurationForZone(getDurationForNewWeek(trainingSettings), ZONES[5]) - hardTrainingDuration

  if (hardTrainingDay) {
    newPlan[hardTrainingDay.dayOfWeek] = {
      ...newPlan[hardTrainingDay.dayOfWeek],
      status: DayStatus.PLANNED,
    }
  }

  while (!hardTrainingDay || remainingHardTrainingDurationToOptimum > MINIMUM_HARD_T_DURATION) {
    const dayForHardTraining = getRandomDayForHardTraining(newPlan)
    if (dayForHardTraining !== null) {
      const intervalTraining = randomIntervalTraining(
        _.clamp(
          remainingHardTrainingDurationToOptimum,
          MINIMUM_HARD_T_DURATION,
          MAXIMUM_HARD_T_DURATION,
        ),
      )
      if (!intervalTraining) {
        console.log('not interval training found')
        return null
      }
      const trainings: Training[] = [
        {
          type: TrainingType.INTERVAL_WARMUP,
          duration: WARM_UP_DURATION,
          sport: SPORTS[2],
          zone: ZONES[1],
        },
        intervalTraining,
        {
          type: TrainingType.INTERVAL_COOLDOWN,
          duration: COOL_DOWN_DURATION,
          sport: SPORTS[2],
          zone: ZONES[1],
        },
      ]

      remainingHardTrainingDurationToOptimum -= intervalTraining.duration!
      hardTrainingDay = dayForHardTraining

      newPlan = appendTrainingToWeek(newPlan, dayForHardTraining, trainings)
      newPlan[dayForHardTraining.dayOfWeek].status = DayStatus.PLANNED
    } else {
      console.log('no day for hard training found')
      break
    }
  }

  let remainingTime = getDurationForNewWeek(trainingSettings) - getDuration(newPlan)

  const longTrainingPlanned = newPlan.some((day) =>
    day.trainings.some((training) => isLong(training)),
  )

  // If no long training is planned or the planned time is not enough
  if (!longTrainingPlanned) {
    const dayForLongTraining = getRandomDayForLongTraining(newPlan)
    if (dayForLongTraining !== null) {
      const remainingDays = getAvailableDays(newPlan)

      const minDuration = remainingTime - (remainingDays.length - 1) * MAXIMUM_NORMAL_T_DURATION
      const maxDuration = remainingTime - (remainingDays.length - 1) * MINIMUM_NORMAL_T_DURATION

      let duration = getOptiDurationForZone(getDurationForNewWeek(trainingSettings), ZONES[1])
      duration = _.clamp(
        duration,
        getMinDurationForZone(getDurationForNewWeek(trainingSettings), ZONES[1]),
        getMaxDurationForZone(getDurationForNewWeek(trainingSettings), ZONES[1]),
      )
      duration = _.clamp(duration, minDuration, maxDuration)
      duration = _.clamp(duration, MINIMUM_LONG_T_DURATION, MAXIMUM_LONG_T_DURATION)
      duration = roundToMultipleOf5(duration)

      const longTraining = {
        type: TrainingType.LONG,
        sport: SPORTS[3],
        duration,
        zone: ZONES[1],
      }

      newPlan = appendTrainingToWeek(newPlan, dayForLongTraining, longTraining)
      newPlan[dayForLongTraining.dayOfWeek].status = DayStatus.PLANNED

      remainingTime = getDurationForNewWeek(trainingSettings) - getDuration(newPlan)
    } else {
      console.log('no day for long training found')
    }
  }

  // Distribute remaining Time

  if (remainingTime <= 0) return newPlan

  const maxDays = Math.floor(remainingTime / MINIMUM_NORMAL_T_DURATION)
  const shuffledRemainingDays = _.shuffle(getAvailableDays(newPlan)).slice(0, maxDays)

  const remainingTimePerDay = _.clamp(
    remainingTime / shuffledRemainingDays.length,
    0,
    MAXIMUM_NORMAL_T_DURATION,
  )

  if (remainingTimePerDay < MINIMUM_NORMAL_T_DURATION) {
    console.log('remaining time after adding long training not in boundaries')
    return newPlan
  }

  shuffledRemainingDays.forEach((day) => {
    let newTraining: Training = {
      type: TrainingType.NORMAL,
      sport: SPORTS[1],
      duration: roundToMultipleOf5(remainingTimePerDay),
      zone: ZONES[1],
    }

    if (
      getOptiDurationForZone(getDurationForNewWeek(trainingSettings), ZONES[1]) -
        getDurationForZone(newPlan, ZONES[1]) >
      REMAINING_DURATION_TO_OPTIMUM
    )
      newTraining.zone = ZONES[1]
    else if (
      getOptiDurationForZone(getDurationForNewWeek(trainingSettings), ZONES[2]) -
        getDurationForZone(newPlan, ZONES[2]) >
      REMAINING_DURATION_TO_OPTIMUM
    )
      newTraining.zone = ZONES[2]
    else if (
      getOptiDurationForZone(getDurationForNewWeek(trainingSettings), ZONES[1]) -
        getDurationForZone(newPlan, ZONES[1]) +
        getOptiDurationForZone(getDurationForNewWeek(trainingSettings), ZONES[2]) -
        getDurationForZone(newPlan, ZONES[2]) >
      REMAINING_DURATION_TO_OPTIMUM
    ) {
      newTraining.type = TrainingType.MIX_1
      newTraining.zone = ZONES[1]
      newTraining.duration = roundToMultipleOf5(remainingTimePerDay / 2)
      newPlan = appendTrainingToWeek(newPlan, day, { ...newTraining })
      newTraining = { ...newTraining }
      newTraining.type = TrainingType.MIX_2
      newTraining.zone = ZONES[2]
    } else if (
      getOptiDurationForZone(getDurationForNewWeek(trainingSettings), ZONES[3]) -
        getDurationForZone(newPlan, ZONES[3]) >
      REMAINING_DURATION_TO_OPTIMUM
    )
      newTraining.zone = ZONES[3]
    else if (
      getOptiDurationForZone(getDurationForNewWeek(trainingSettings), ZONES[4]) -
        getDurationForZone(newPlan, ZONES[4]) >
      REMAINING_DURATION_TO_OPTIMUM
    )
      newTraining.zone = ZONES[4]
    else {
      newTraining.type = TrainingType.MIX_1
      newTraining.zone = ZONES[3]
      newTraining.duration = roundToMultipleOf5(remainingTimePerDay / 2)
      newPlan = appendTrainingToWeek(newPlan, day, newTraining)
      newTraining = { ...newTraining }
      newTraining.type = TrainingType.MIX_2
      newTraining.zone = ZONES[4]
    }

    newPlan = appendTrainingToWeek(newPlan, day, newTraining)
    newPlan[day.dayOfWeek].status = DayStatus.PLANNED
  })

  return newPlan
}

export { generateTrainingsPlan, roundToMultipleOf5 }
