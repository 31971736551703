import React from 'react'

import {
  Box,
  Heading,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  useColorModeValue,
} from '@chakra-ui/react'
import OnboardingCard from '../OnboardingCard'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { MAXIMUM_FOR_DAYS, MINIMUM_T_DURATION } from '../../constants/durations'
import { DayStatus } from '../../models/day'
import { setMaxTime } from '../../reducers/trainingSettingsReducer'
import { PRIMARY_DARK, PRIMARY_LIGHT } from '../../constants/colors'

const MaxTimeForActivities = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const week = useAppSelector((state) => state.trainingSettingsReducer.week)

  const handleNext = () => {
    dispatch(setMaxTime(sliderValue))
    navigate('/onboarding/summary')
  }

  const days = week.filter(
    (day) =>
      day.status === DayStatus.AVAILABLE ||
      day.status === DayStatus.PLANNED ||
      day.status === DayStatus.PREFERRED_HARD ||
      day.status === DayStatus.PREFERRED_LONG,
  ).length
  const maxVal = MAXIMUM_FOR_DAYS[days - 1]

  const [sliderValue, setSliderValue] = useState(maxVal)

  const sliderColor = useColorModeValue(PRIMARY_LIGHT, PRIMARY_DARK)

  return (
    <OnboardingCard
      title='Wie viel Zeit möchtest du dir pro Woche maximal für Bewegung nehmen?'
      onNextClicked={handleNext}
    >
      <Heading size='sm' fontStyle='italic' color='gray.400'>
        wenn du mehr Zeit investieren möchtest, stelle bitte mehr Tage zur Verfügung
      </Heading>
      <Box my='10' px='8'>
        <Slider
          value={sliderValue}
          min={MINIMUM_T_DURATION}
          max={maxVal}
          step={10}
          onChange={(val) => setSliderValue(val)}
        >
          <SliderMark value={MINIMUM_T_DURATION} mt='4' ml='-6' fontSize='sm'>
            {MINIMUM_T_DURATION} min
          </SliderMark>
          <SliderMark value={maxVal} mt='4' ml='-16' fontSize='sm'>
            {maxVal} min
          </SliderMark>
          <SliderMark
            value={sliderValue}
            textAlign='center'
            bg={sliderColor}
            color='white'
            mt='-12'
            ml='-10'
            w='20'
            px='1'
            borderRadius='md'
          >
            {sliderValue} min
          </SliderMark>
          <SliderTrack>
            <SliderFilledTrack bg={sliderColor} />
          </SliderTrack>
          <SliderThumb boxSize={6} bg={sliderColor} />
        </Slider>
      </Box>
    </OnboardingCard>
  )
}

export default MaxTimeForActivities
