import React from 'react'

import { Center, Heading, useCheckboxGroup } from '@chakra-ui/react'
import OnboardingCard from '../OnboardingCard'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { DayStatus, nextDay, prevDay } from '../../models/day'
import { TrainingType, isHard, isLong } from '../../models/training'
import { setTraining } from '../../reducers/trainingSettingsReducer'
import { getAvailableDays } from '../../models/week'
import { ONBOARDING_STEPS } from '../../App'
import CircleCheckboxItem from '../CircleCheckboxItem'
import NodeCircle from '../RadioCircle'

const StrengthTraining = () => {
  const { value, getCheckboxProps } = useCheckboxGroup()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const week = useAppSelector((state) => state.trainingSettingsReducer.week)

  const handleNext = () => {
    const days = value.map((v) => parseInt(v as string))
    if (days.length < 2 || days.length > 3) return
    for (const day of days) {
      dispatch(setTraining({ training: { type: TrainingType.STRENGTH }, dayOfWeek: day }))
    }
    navigate('/onboarding/' + ONBOARDING_STEPS.maxTimeForActivities.key)
  }

  const availableDaysForHardTraining = getAvailableDays(week).filter((day) => {
    const _prevDay = week[prevDay(day)]
    const prevIsLong = _prevDay.trainings.some((t) => isLong(t))
    const prevIsPrefLong = _prevDay.status === DayStatus.PREFERRED_LONG
    const prefLongTrainingToday = day.status === DayStatus.PREFERRED_LONG
    const strengthTrainingToday = value.includes(day.dayOfWeek.toString())

    return (
      !prevIsLong &&
      !prevIsPrefLong &&
      !prefLongTrainingToday &&
      !strengthTrainingToday
    )
  })

  return (
    <OnboardingCard
      title='An welchen Tagen dürfen wir dir ein Krafttraining planen?'
      onNextClicked={handleNext}
      isNextDisabled={value.length < 2}
    >
      <Heading size='sm'>mindestens 2 Tage, maximal 3</Heading>
      <Heading size='sm'>
        bitte beachte, dass an Tagen an denen ein Krafttraining geplant ist, keine rote Einheit
        geplant werden kann
      </Heading>
      <Center>
        <NodeCircle>
          {week.map((day) => {
            const dayOfWeek = day.dayOfWeek.toString()
            const isHardTraining = day.trainings.some((t) => isHard(t))
            const isPrefHardTraining = day.status === DayStatus.PREFERRED_HARD
            const threeSelected = value.length === 3 && !value.includes(dayOfWeek)

            const lastPossibleDayForHardTraining =
              availableDaysForHardTraining.length === 1 && availableDaysForHardTraining[0] === day

            const disabled =
              isHardTraining ||
              isPrefHardTraining ||
              threeSelected ||
              lastPossibleDayForHardTraining

            const checkbox = getCheckboxProps({ value: dayOfWeek })

            return (
              <CircleCheckboxItem key={dayOfWeek} isDisabled={disabled} {...checkbox}>
                {day.short}
              </CircleCheckboxItem>
            )
          })}
        </NodeCircle>
      </Center>
    </OnboardingCard>
  )
}

export default StrengthTraining
