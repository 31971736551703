import { Center, Container, Heading, useColorModeValue, useRadioGroup } from '@chakra-ui/react'

import NavBar from './components/NavBar'
import ProgressTracker from './components/ProgressTracker'
import CurrentTimeForActivities from './components/onboarding_steps/CurrentTimeForActivities'
import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { ERROR_DARK, ERROR_LIGHT } from './constants/colors'
import PlannedHardTraining from './components/onboarding_steps/PlannedHardTraining'
import AvailableDays from './components/onboarding_steps/AvailableDays'
import PreferredDayHard from './components/onboarding_steps/PreferredDayHard'
import PreferredDayLong from './components/onboarding_steps/PreferredDayLong'
import StrengthTraining from './components/onboarding_steps/StrengthTraining'
import MaxTimeForActivities from './components/onboarding_steps/MaxTimeForActivities'
import Summary from './components/Summary'
import Home from './components/Home'
import NodeCircle from './components/RadioCircle'
import CircleRadioItem from './components/CircleRadioItem'
import { WEEK } from './models/week'

const ONBOARDING_STEPS = {
  currentTimeForActivities: <CurrentTimeForActivities key='1' />,
  plannedHardTraining: <PlannedHardTraining key='2' />,
  availableDays: <AvailableDays key='3' />,
  prefDayForHard: <PreferredDayHard key='4' />,
  prefDayForLong: <PreferredDayLong key='5' />,
  strengthTraining: <StrengthTraining key='6' />,
  maxTimeForActivities: <MaxTimeForActivities key='7' />,
}

const App = () => {
  const steps = Object.values(ONBOARDING_STEPS)

  const location = useLocation()
  const stepNumber =
    location.pathname === '/onboarding/summary'
      ? steps.length + 1
      : parseInt(location.pathname.replace(/\D*/, ''))

  const { getRadioProps, value } = useRadioGroup()

  return (
    <>
      <NavBar />
      <ProgressTracker length={steps.length} step={stepNumber - 1} />
      <Container maxW='container.lg'>
        <Center>
          <Routes>
            {steps.map((step, index) => (
              <Route key={index} path={'/onboarding/' + step.key} element={step} />
            ))}
            <Route path='/onboarding/summary' element={<Summary />} />
            <Route path='/' element={<Home />} />
            <Route
              path='/*'
              element={
                <Heading color={useColorModeValue(ERROR_LIGHT, ERROR_DARK)}>
                  Route not found
                </Heading>
              }
            />
          </Routes>
        </Center>
      </Container>
    </>
  )
}

export default App
export { ONBOARDING_STEPS }
