import React, { ReactNode } from 'react'
import { Box, UseRadioProps, useRadio } from '@chakra-ui/react'
import CircleSelectItem from './CircleSelectItem'

const CircleRadioItem = (props: UseRadioProps & { children: ReactNode }) => {
  const { getInputProps, getRadioProps, state } = useRadio(props)

  const input = getInputProps()
  const radio = getRadioProps()

  return (
    <CircleSelectItem state={state} input={input} inputProps={radio}>
      {props.children}
    </CircleSelectItem>
  )
}

export default CircleRadioItem
